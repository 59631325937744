@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

p {
  line-height: 1.2rem;
  text-align: justify;
}

:root {
  --colour-bg: #fff;
  --colour-bg-variant: #ffd676;
  --colour-primary: #0072d2;
  --colour-primary-light: #7ac2ff;
  --colour-primary-variant: #ff0000;
  --colour-text: #000;
  --colour-light: #ffb2b2;
  --colour-primary-shadow: rgba(0, 0, 0, 0.25);

  --button-margin: 20px;
  --container-margin: 70px;
}
@media screen and (max-width: 1024px) {
  :root {
    --button-margin: 5px;
  }
}
