.collapsible_card {
    position: relative;
    padding: 0 2rem 1rem 0;
    display: grid;
    color: black;
    grid-template-columns: 0.7fr 1.3fr;
    text-align: left;
    backdrop-filter: blur(25px);
    -ms-filter: blur(25px);
    background-color: rgba(255, 255, 255, 0.6);
    mask: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.99) 30%, rgba(0, 0, 0, 1) 32%, rgba(0, 0, 0, 1) 120%);
    transition: all 0.3s;
    border-radius: 2rem;
}

.collapsible_card:hover {
    background-color: rgba(195, 195, 255, 0.85);
    transition: all 0.3s;
}

.collapsible_card_inner {
    display: grid;
    grid-template-columns: 0.8fr 0.2fr;
}

.second_title_button {
    align-self: center;
    text-align: right;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 750px) {
    .collapsible_card {
        mask: none;
        grid-template-columns: 1fr;
        padding: 1rem;
        border-radius: 1.2rem;
    }

    .collapsible_card:hover {
        border-radius: 1.2rem;
    }

    .collapsible_card h3 {
        font-size: 1rem;
    }
    .collapsible_card h4 {
        font-size: 0.9rem;
    }
}
