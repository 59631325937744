#events-container {
    position: relative;

    top: var(--button-margin);
    bottom: var(--button-margin);
    left: var(--button-margin);
    right: var(--container-margin);

    height: calc(100% - 2 * var(--button-margin));
    width: calc(100% - var(--container-margin) - var(--button-margin));

    box-shadow: 0 0 30px 2px black;
    border-radius: 4rem;
    color: white;

    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    gap: 1rem;

    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    background: radial-gradient(circle at top left, transparent 0, black 50%),
    radial-gradient(circle at 10% 50%, rgb(0, 178, 0), transparent 400px),
    radial-gradient(circle at 55% 15%, rgb(255, 50, 50), transparent 500px),
    radial-gradient(circle at 20px 20px, rgb(0, 93, 208), transparent);
}

#events-container h2 {
    color: var(--colour-primary-light);
}

#events-container h3 {
    color: var(--colour-light);
}

#events-container::-webkit-scrollbar {
    display: none;
}

#aya-sidebar {
    position: relative;
    top: 0;
    padding: 4rem;
    align-items: center;

    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.sidebar-picture {
    width: 20vw;
    border-radius: 1rem;
    height: auto;
    position: relative;
    display: block;
    box-shadow: 0 0 70px 1px var(--colour-primary);
}

#aya-sidebar a {
    color: white;
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
}

#aya-sidebar a:hover {
    color: var(--colour-primary);
}

#aya-logo {
    width: 40%;
    padding: 2rem 0 2rem 0;
}

#aya-content {
    padding: 4rem 4rem 4rem 4rem;
}

.aya-event {
    padding: 2rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 1rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    gap: 1rem;
    align-items: center;
    height: fit-content;
}

.aya-event hr {
    display: none;
    width: 40%;
    margin-left: 10px;
    border: none;
    height: 1px;
    background: var(--colour-light);
}

@media screen and (max-width: 1300px) {
    #aya-logo {
        width: 60%;
    }
}

@media screen and (max-width: 900px) {
    #events-container {
        display: flex;
        flex-direction: column;
        border-radius: 2rem;
        gap: 0;
    }

    #aya-sidebar {
        gap: 0;
    }

    #aya-logo {
        width: 40%;
        padding: 2rem;
        align-items: center;
    }

    #aya-logo img {
        position: relative;
    }

    #aya-content {
        padding: 0 2rem 0 2rem;
    }

    #vibe {
        margin-top: 2rem;
        display: block;
        width: 60vw;
    }

    .sidebar-picture {
        display: none;
    }

    .aya-event {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        padding: 0 1rem 0 1rem;
    }
    .aya-event hr {
        display: block;
    }
}