#home-container {
    padding: 2rem;
    display: grid;
    grid-template-columns: 44% 55%;
    border-radius: 4rem;
    position: absolute;
    top: var(--container-margin);
    bottom: var(--container-margin);
    left: calc(5% + var(--container-margin));
    right: calc(5% + var(--container-margin));
    align-items: center;
    gap: 1rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

#home-container::-webkit-scrollbar {
    display: none;
}

.profile-card {
    background: var(--colour-bg);
    box-shadow: 0 0 50px 1px var(--colour-primary-shadow);
    border-radius: 3.5rem;
    padding: 2rem;
    position: relative;
    text-align: center;
    width: 60%;
    left: 18%;
}

.profile-card h2 {
    font-weight: 600;
    font-size: 2vw;
    line-height: 0;
    margin: 2.5rem 0 0 0;
}

.profile-picture {
    box-shadow: 0 0 25px 5px var(--colour-primary-shadow);
    position: relative;
    object-fit: revert;
    overflow: hidden;
    border-radius: 2rem;
}

.profile-card-buttons {
    margin-top: 0.5rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    gap: 1rem;
    position: relative;
    font-size: 1.5rem;
}

.about-container {
    text-align: justify;
    position: relative;
    margin-right: 2rem;
}

.about-container h1 {
    font-weight: 800;
    font-size: 3vw;
    line-height: 3.5vw;
    background: linear-gradient(90deg, var(--colour-primary), var(--colour-primary-variant));
    background-clip: text;
    color: transparent;
    margin-bottom: 2rem;
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--colour-bg);
    background: var(--colour-primary);
    padding: 0.5rem 0.8rem;
    border-radius: 0.7rem;
    font-size: 0.9rem;
    font-weight: bold;
    border: 1px solid transparent;
}

.btn:hover {
    background: var(--colour-primary-variant);
    color: var(--colour-bg);
    border-color: transparent;
    box-shadow: none;
}

.social {
    color: var(--colour-primary);
}

.social:hover {
    color: var(--colour-primary-variant);
}

.divider {
    display: none;
    width: 50%;
    margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
    .profile-card {
        left: 5%;
        width: 70%;
    }

    .about-container {
        margin-left: 1rem;
        margin-right: 0;
    }

    .about-container h1 {
        font-size: 2.7vw;
    }
}

/* MEDIA QUERIES */
@media screen and (max-width: 900px) {
    #home-container {
        padding: 0;
        grid-template-columns: 1fr;
        align-items: center;
        box-shadow: 0 0 40px 1px var(--colour-primary-shadow);
        overflow: scroll;
        touch-action: pan-y;
        cursor: grabbing;
        -ms-touch-action: pan-y;
        -webkit-overflow-scrolling: touch;
        left: var(--container-margin);
        right: var(--container-margin);
        border-radius: 2rem;
    }

    .profile-card {
        box-shadow: none;
        left: 0;
        width: auto;
    }

    .profile-card h2 {
        font-size: 1.2rem;
    }

    .profile-picture {
        border-radius: 1rem;
        width: 60%;
        left: 20%;
    }

    .about-container {
        margin: 2rem;
    }

    .about-container h1 {
        font-size: 6vw;
        line-height: 7vw;
        margin: 0 0 5vw 0;
    }

    .divider {
        display: block;
    }

    :root {
        --container-margin: 40px;
    }
}

@media screen and (max-width: 375px) {
    .profile-card-buttons {
        flex-direction: column;
        align-items: center;
    }

    .about-container h1 {
        font-size: 6vw;
    }
}